* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Alegreya Sans", sans-serif !important;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  .App {
    min-height: 100vh;
    width: 85vw;
    max-width: 1900px;
    margin: 0rem auto;
  }
  body {
    max-width: 100%;
    overflow-x: hidden;
  }
  
  body {
    background-color: #f6f6f6;
  }
  
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
  }
  .navbar-menu-container {
    display: none;
  }
  .navbar-links-container a {
    margin-right: 3rem;
    text-decoration: none;
    color: #D58FC4;
    font-size: 1.1rem;
    font-weight: 600;
  }
  .nav-logo-container h1 {
    color: #D4AC26;
  }

  .home-banner-container {
    position: relative;
    display: flex;
    padding: 3em;
  }

  .home-text-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-image-section {
    max-width: 600px;
    flex: 1;
    margin-right:10em;
  }

  .event-details-main-text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3em;
  }

  .where-to-stay-main-text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3em;
  }

  .puzzles-games-form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3em;
  }

  .puzzles-games-forms {
    text-align: left;
    padding-left: 3em;
    font-size: clamp(1rem, 3vw, 1.5rem);
    max-width: 700px;
    color: #D4AC26;
  }

  .puzzles-games-input {
    padding-left: 3em;
  }

  .puzzles-games-question {
    margin-bottom: 20px;
  }

  .puzzles-games-photos-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3em;
  }

  .puzzles-games-photos-container video {
    margin-bottom: 20px;
  }

  .puzzles-games-photos-container img {
    max-width: 600px;
    margin-bottom: 20px;
  }

  .where-to-stay-main-text-container iframe {
    width: 50%;
    height: 30em
  }

  .primary-heading {
    font-size: clamp(2rem, 5vw, 4rem);
    color: #D4AC26;
    /* line-height: 5rem; */
    max-width: 600px;
    text-align: center;
  }

  .primary-text {
    font-size: clamp(1rem, 3vw, 1.5rem);
    max-width: 700px;
    text-align: center;
    color: #D4AC26;
  }

  .primary-text a {
    color: #D58FC4;
  }

  .supporting-text {
    font-size: clamp(0.75rem, 2.25vw, 1.125rem);
    max-width: 700px;
    text-align: left;
    color: #D4AC26;
  }

  .supporting-list {
      font-size: clamp(0.75rem, 2.25vw, 1.125rem);
      padding-left: 2.5em;
      max-width: 700px;
      color: black;
  }

  @media (max-width: 1000px) {
    .navbar-links-container a {
      margin-right: 1rem;
      font-size: 1rem;
    }

    .home-bannerImage-container {
        max-width: 600px;
    }

    .where-to-stay-main-text-container iframe {
      width: 100%;
      height: 20em
    }
  }

  @media (max-width: 800px) {
    .nav-logo-container {
      max-width: 140px;
    }
    .navbar-links-container {
      display: none;
    }
    .navbar-menu-container {
      display: flex;
    }

    .home-bannerImage-container {
        max-width: 450px;
      }

    .home-banner-container{
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    }

    .home-image-section {
      width: 100%;
      max-width: 400px;
      margin-right: 0em;
    }

    .App {
      max-width: 95vw;
    }

    .where-to-stay-main-text-container iframe {
      width: 100%;
      height: 25em
    }

    .puzzles-games-photos-container img {
      max-width: 400px;
      margin-bottom: 20px;
    }

    .puzzles-games-photos-container video {
      max-width: 400px;
    }
  }